<template>
  <UniversalDialog
    persistent
    overlay-opacity="0.6"
    max-width="640"
    :value="openDialog"
  >
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->

    <template v-slot:footer>
      <UniversalButton width="120" @click="confirm()" :color="okvariant">
        {{ !oktext ? $t("gbl-ok") : oktext }}
      </UniversalButton>

      <UniversalButton
        width="120"
        v-if="!okonly"
        @click="dismiss()"
        :color="cancelvariant"
        secondary
      >
        {{ !canceltext ? $t("msgbox-cancel") : canceltext }}
      </UniversalButton>
    </template>

    <template v-slot:title>
      <v-row
        ><v-col cols="11">{{ title }}</v-col
        ><v-col cols="1" class="text-right"
          ><v-btn
            style="margin-right: 0"
            v-if="showClose"
            icon
            @click="dismiss()"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-col
        ></v-row
      >
    </template>

    <template v-slot:default>
      <span class="body-1">{{ text }}</span>
    </template>
  </UniversalDialog>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      text: "",
      okvariant: "#cb0c9f",
      cancelvariant: "#cb0c9f",
      okonly: false,
      openDialog: false,
      dialogResolve: null,
      dialogReject: null,
      oktext : null,
      canceltext : null
    };
  },

  props: {
    showClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  methods: {
    show(
      text,
      title,
      okonly = false,
      okvariant = "primary",
      cancelvariant = "secondary",
      oktext = null,
      canceltext = null
    ) {
      return new Promise((resolve, reject) => {
        this.value = true;
        this.title = title;
        this.text = text;
        this.okvariant = okvariant;
        this.cancelvariant = cancelvariant;
        this.okonly = okonly;
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;
        this.oktext = oktext;
        this.canceltext = canceltext;
      });
    },

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      this.openDialog = false;
      this.dialogResolve();
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    },
  },
};
</script>
