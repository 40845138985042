const rightsServices = (function () {
  return {
    isAdmin: function (rights) {
      if (!rights) return false;
      if (!Array.isArray(rights)) rights = rights.split(",");
      if (rights.includes("a") || rights.includes("sa")) return true;
      return false;
    },

    isSuperAdmin: function (rights) {
      if (!rights) return false;
      if (!Array.isArray(rights)) rights = rights.split(",");
      if (rights.includes("sa")) return true;
      return false;
    },

    hasExactRight: function (rights, right) {
      if (!right) return true;
      right = right.toString(); // Default to string
      if (!rights) return false;
      if (!Array.isArray(rights)) rights = rights.split(",");
      if (rights.includes(right)) return true;
      return false;
    },

    hasRight: function (rights, right) {
      if (!right) return true;
      right = right.toString(); // Default to string
      if (rights) rights = rights.toString().split(",");
      if (!rights) return false;
      if (!Array.isArray(rights)) rights = rights.split(",");
      if (rightsServices.isSuperAdmin(rights) || rightsServices.isAdmin(rights))
        return true;
      if (rights.includes(right)) return true;
      return false;
    },
  };
})();

export default rightsServices;
