<template>
  <SharedFooter />
</template>
<script>
import SharedFooter from "@/components/mockup/SharedFooter.vue";

export default {
  name: "auth-footer",

  components: {
    SharedFooter,
  },
};
</script>
