import axios from "axios";
import config from "@/config";

let context = {
  fetchContext: () => {
    return new Promise((resolve, reject) => {
      let opts = {
        url: config.contextApiEndPoint + "/fetchContext",
        data: {},
        method: "POST",
      };
      axios(opts)
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default context;
