<template>
    <UniversalDialog
      persistent
      overlay-opacity="0.6"
      max-width="480"
      :value="openDialog"
    >
      <template v-slot:footer="footerProps">
        <UniversalButton :disabled="footerProps.invalid" width="120" @click="confirm()" :color="okvariant">
          {{ $t("gbl-ok") }}
        </UniversalButton>
  
        <UniversalButton
          width="120"
          @click="dismiss()"
          :color="cancelvariant"
          secondary
        >
          {{ $t("msgbox-cancel") }}
        </UniversalButton>
      </template>
  
      <template v-slot:title>
        <v-row
          ><v-col cols="11">{{ title }}</v-col
          ><v-col cols="1" class="text-right"
            ><v-btn
              style="margin-right: 0"
              v-if="showClose"
              icon
              @click="dismiss()"
              ><v-icon>mdi-close</v-icon></v-btn
            ></v-col
          ></v-row
        >
      </template>
  
      <template v-slot:default>
        <span v-if="text">{{ text }}</span>
          <ValidationProvider immediate rules="required" v-slot="{ errors, valid }">
            <FormTextDatePicker
              v-model="value"
              :label="label"
              :error-messages="errors"
              :success="valid"
              required
            ></FormTextDatePicker>
          </ValidationProvider>
      
      </template>
    </UniversalDialog>
  </template>
  
  <script>
  export default {
    data() {
      return {
        title: "",
        label: "",
        value: "",
        text: null,
        initial_value: "",
        string_state: true,
        context: null,
        mustChange: false,
        okvariant: "primary",
        cancelvariant: "secondary",
        openDialog: false,
        dialogResolve: null,
        dialogReject: null,
      };
    },
  
    props: {
      showClose: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
  
    methods: {
      show(
        title,
        label,
        text,
        initial_value,
        context = null,
        mustChange = true,
        okvariant = "primary",
        cancelvariant = "secondary"
      ) {
        return new Promise((resolve, reject) => {
          this.value = initial_value;
          this.title = title;
          this.label = label;
          this.text = text;
          this.initial_value = initial_value;
          this.okvariant = okvariant;
          this.cancelvariant = cancelvariant;
          this.dialogResolve = resolve;
          this.dialogReject = reject;
          this.openDialog = true;
          this.context = context;
          this.mustChange = mustChange;
        });
      },
  
      hide() {
        this.openDialog = false;
        this.dialogReject();
      },
  
      confirm() {
        if (this.value.length > 0) {
          if (this.value != this.initial_value || !this.mustChange) {
            this.openDialog = false;
            this.dialogResolve({ context: this.context, value: this.value });
          }
        }
      },
  
      dismiss() {
        this.hide();
      },
  
      closeDialog() {
        this.hide();
      },
    },
  };
  </script>
  