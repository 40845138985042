// module variables
const config = require("./config.json");
const defaultConfig = config.development;
const environment = process.env.NODE_ENV || "production";
const environmentConfig = config[environment];
let finalConfig = Object.assign({}, defaultConfig, environmentConfig);

/*if (environment == "production") {
  let base_url = window.location.origin;
  finalConfig.apiEndPoint = base_url + "/api";
  finalConfig.contextApiEndPoint = base_url + "/api";
}*/

export default finalConfig;
