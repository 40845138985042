import axios from "axios";
import config from "@/config";

let transactions = new Object({
  fetchTransactions: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/transactions/fetchTransactions",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.transactions,
            totalCount: resp.data.responseData.transactionsCount,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
});

export default transactions;
