<template>
  <v-dialog ref="dialog" v-model="modal" width="290px">
    <template v-slot:activator="{ on }">
      <p
        class="text-left text-secondary text-caption ml-2 pb-0 pt-0 mb-0 mt-0"
        v-if="label"
      >
        {{ label }}
      </p>

      <v-menu
        v-model="suggestionsMenu"
        max-width="290px"
        offset-y
        min-width="290px"
        :open-on-click="false"
        :close-on-click="false"
      >
        <template v-slot:activator="{ on }">
          <FormTextInput
            ref="textField"
            v-model="date"
            v-on="on"
            @keydown="nameKeydown($event)"
            outlined
            :placeholder="getPlaceholder"
            :persistent-placeholder="true"
            append-icon="mdi-calendar"
            @click:append="modal = true"
            @blur="onBlur()"
            :error-messages="errorMessages"
            :success="success"
            :background-color="getBackgroundColor"
            :disabled="disabled"
          >
            <template v-slot:append>
              <v-btn
                :disabled="disabled"
                @click="modal = true"
                x-small
                text
                icon
                class="mt-1"
                color="#8598ca"
                v-on="on"
                ><v-icon size="20">mdi-calendar</v-icon></v-btn
              >
            </template></FormTextInput
          >
        </template>
        <v-list>
          <v-list-item
            v-on:mousedown="setMenuDate(item)"
            v-for="(item, index) in suggestionItems"
            :key="index"
          >
            {{ item.title }}
          </v-list-item>
        </v-list>
      </v-menu>

      <p
        class="text-left text-primary text-caption ml-2 mb-2 mt-0"
        v-if="errorMessages[0]"
      >
        {{ errorMessages[0] ? errorMessages[0] : "" }}
      </p>
    </template>
    <v-date-picker color="#8598ca" v-model="pickerDate" scrollable>
      <v-spacer></v-spacer>

      <UniversalButton text secondary @click="dismissDialog()"
        >{{ $t("gbl-cancel") }}
      </UniversalButton>
      <UniversalButton text primary @click="dialogConfirmed()"
        >{{ $t("gbl-ok") }}
      </UniversalButton>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import dates from "@/services/dates";

export default {
  data: () => ({
    date: null,
    modal: null,
    pickerDate: null,
    suggestionsMenu: false,
    suggestionItems: [],
  }),

  watch: {
    date(val) {
      if (!val) return;
      if (val.length == 2 || val.length == 5)
        val += this.locale == "it" ? "/" : "-";
      if (val.length >= 11) val = val.substr(0, 10);
      this.date = val;
      this.buildSuggestionItems();
    },

    value() {
      this.date = this.padDate(
        dates.dateFromIsoToLocale(this.value, this.locale)
      );
    },
  },

  mounted() {
    this.date = this.padDate(
      dates.dateFromIsoToLocale(this.value, this.locale)
    );
  },

  computed: {
    getPlaceholder: {
      get() {
        if (this.locale == "it") return "GG/MM/AAAA";
        else return "MM-DD-YYYY";
      },
    },

    getClass() {
      let baseClass = this.$props.secondary
        ? this.$props.disabled
          ? "disabled"
          : "secondary"
        : this.$props.disabled
        ? "disabled"
        : "default";
      return (
        "font-weight-bolder btn-default py-4 px-8 mt-4 bg-gradient-" + baseClass
      );
    },

    getTextColor() {
      return "color : " + this.$props.textColor;
    },

    getBackgroundColor() {
      if (this.disabled) return "rgba(240,240,240,.9)";
      return "rgba(255,255,255,.9)";
    },
  },

  props: {
    value: {
      type: String,
      required: false,
    },
    overlayOpacity: {
      type: String,
      required: false,
      default: "1.0",
    },

    errorMessages: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },

    success: {
      type: Boolean,
      required: false,
      default: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    label: {
      type: String,
      required: false,
      default: "",
    },
    persistent: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxWidth: {
      type: String,
      required: false,
      default: null,
    },

    locale: {
      type: String,
      required: false,
      default: "it",
    },

    yearAutoComplete: {
      type: Boolean,
      required: false,
      default: true,
    },

    menuSuggestions: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    setMenuDate(item) {
      this.date = item.title;
    },

    buildSuggestionItems() {
      this.suggestionItems = [];
      let dateNow = new Date(Date.now());
      let isoDate = this.padDate(
        dates.dateFromIsoToLocale(dateNow.toISOString(), this.locale)
      );
      this.suggestionItems = [{ title: isoDate }];

      if (this.date) {
        let items = [];
        if (this.locale == "it") items = this.date.split("/");
        else items = this.date.split("-");
        if (this.date.length == 3 && items.length >= 1) {
          this.suggestionItems = [];
          if (this.locale == "it") {
            for (let x = 1; x <= 12; x++) {
              let month = x.toString();
              if (month.length == 1) month = "0" + month;
              this.suggestionItems.push({
                title: items[0] + "/" + month + "/" + dateNow.getFullYear(),
              });
            }
          } else {
            for (let x = 1; x <= 31; x++) {
              let day = x.toString();
              if (day.length == 1) day = "0" + day;
              this.suggestionItems.push({
                title: items[0] + "-" + day + "-" + dateNow.getFullYear(),
              });
            }
          }
        } else if (this.date.length == 6 && items.length >= 2) {
          this.suggestionItems = [];
          for (
            let x = dateNow.getFullYear();
            x >= dateNow.getFullYear() - 20;
            x--
          ) {
            let year = x.toString();
            if (this.locale == "it") {
              this.suggestionItems.push({
                title: items[0] + "/" + items[1] + "/" + year,
              });
            } else {
              this.suggestionItems.push({
                title: items[1] + "-" + items[0] + "-" + year,
              });
            }
          }
        }
      }
    },

    dismissDialog() {
      this.modal = false;
    },

    dialogConfirmed() {
      this.modal = false;
      let isoDate = this.pickerDate;
      this.$emit("input", isoDate);
      this.$emit("change");
      this.date = this.padDate(dates.dateFromIsoToLocale(isoDate, this.locale));
    },

    padDate(val) {
      if (!val) return null;
      let parts = [];
      parts = val.split("/");
      if (parts.length < 3) parts = val.split("-");
      if (parts.length < 3) return null;
      while (parts[0].length < 2) parts[0] = "0" + parts[0];
      while (parts[1].length < 2) parts[1] = "0" + parts[1];
      while (parts[2].length < 4) parts[2] = "0" + parts[2];

      if (this.locale == "it") return parts.join("/");
      else return parts.join("-");
    },

    onBlur() {
      this.suggestionsMenu = false;
      if (this.date && this.yearAutoComplete && this.date.length == 8) {
        let split = [];
        if (this.locale == "it") split = this.date.split("/");
        else split = this.date.split("-");

        // Get current year last two digits
        let current_year = new Date().getFullYear().toString().substr(-2);
        // Get date last two digits
        let date_year = split[2].substr(-2);
        let subst_year = 20;
        if (date_year > current_year) {
          subst_year = 19;
        }

        let prefill_date =
          this.locale == "it"
            ? split[0].substr(0, 2) +
              "/" +
              split[1].substr(0, 2) +
              "/" +
              subst_year +
              split[2].substr(0, 2)
            : split[0].substr(0, 2) +
              "-" +
              split[1].substr(0, 2) +
              "-" +
              subst_year +
              split[2].substr(0, 2);

        this.date = prefill_date;
      }

      if (this.date && this.date.length < 10) {
        this.date = null;
        this.$emit("input", this.date);
        this.$emit("change");
      } else if (this.date && this.date.length == 10) {
        let isoDate = dates.dateFromLocaleToIso(this.date, this.locale);
        this.$emit("input", isoDate);
        this.$emit("change");
      } else {
        this.date = null;
        this.$emit("input", this.date);
        this.$emit("change");
      }
    },

    nameKeydown(e) {
      this.suggestionsMenu = true;
      let selectionArea = this.$refs.textField.$el.querySelector("input");
      let selectionLength =
        selectionArea.selectionEnd - selectionArea.selectionStart;
      if ((e.keyCode == 8 || e.keyCode == 46) && selectionLength > 1) {
        this.date = null;
        e.preventDefault();
        return;
      }
      if (
        (e.key == "/" || e.key == "-") &&
        this.date &&
        this.date.length == 1
      ) {
        this.date = "0" + this.date;
        e.preventDefault();
        return;
      }
      if (
        (e.key == "/" || e.key == "-") &&
        this.date &&
        this.date.length == 4
      ) {
        this.date = this.date.substr(0, 3) + "0" + this.date.substr(3, 1);
        e.preventDefault();
        return;
      }

      if (
        this.date &&
        this.date.length >= 10 &&
        e.keyCode != 8 &&
        e.keyCode != 46 &&
        e.keyCode != 9
      ) {
        e.preventDefault();
        return;
      }
      if (
        !/^\d$/.test(e.key) &&
        e.keyCode != 8 &&
        e.keyCode != 46 &&
        e.keyCode != 9
      ) {
        e.preventDefault();
        return;
      }
    },
  },
};
</script>
