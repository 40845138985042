<template>
  <div class="d-flex align-center">
    <span>
      <v-avatar
        class="shadow"
        size="36px"
        v-if="checkGravatar(item) && getGravatar(item)"
      >
        <v-img :src="getGravatar(item)" :alt="getUsername(item)" />
      </v-avatar>

      <v-avatar
        class="shadow profile-text"
        v-else-if="getProfileLetters(item)"
        color="#8598ca"
        size="36px"
      >
        <span>{{ getProfileLetters(item) }}</span>
      </v-avatar>
    </span>
  </div>
</template>

<script>
import AvatarServices from "@/services/avatars";

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: null,
    },
    idKey: {
      type: String,
      required: false,
      default: "id",
    },

    firstNameKey: {
      type: String,
      required: false,
      default: "first_name",
    },

    lastNameKey: {
      type: String,
      required: false,
      default: "last_name",
    },

    businessNameKey: {
      type: String,
      required: false,
      default: "business_name",
    },

    gravatarKey: {
      type: String,
      required: false,
      default: "gravatar",
    },

    customerGravatar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    getGravatar: function (item) {
      if (!item[this.gravatarKey]) return null;
      return "data:image/jpeg;base64," + item[this.gravatarKey];
    },

    buildGravatar: function (item) {
      return (
        "<img style='width:48px; border:1px solid black; border-radius: 50%;' src='data:image/jpeg;base64," +
        item[this.gravatarKey] +
        "'>"
      );
    },

    checkGravatar: function (item) {
      if (
        item[this.idKey] &&
        !item.gravatarFetched &&
        item[this.gravatarKey] != null
      ) {
        item.gravatarFetched = true;
        AvatarServices.fetchAvatar(item[this.idKey], this.customerGravatar)
          .then((result) => {
            item[this.gravatarKey] = result.avatar;
            this.$forceUpdate();
          })
          .catch((err) => {
            console.log(err);
          });
        return true;
      } else if (item.gravatarFetched) {
        return true;
      } else {
        return false;
      }
    },

    getUsername: function (item) {
      return item[this.firstNameKey];
    },

    getProfileLetters: function (user) {
      if (user[this.firstNameKey] && user[this.lastNameKey]) {
        if (user[this.firstNameKey].length && user[this.lastNameKey].length) {
          return (
            user[this.firstNameKey].toUpperCase()[0] +
            user[this.lastNameKey].toUpperCase()[0]
          );
        }
      } else if (
        user[this.firstNameKey] &&
        user[this.firstNameKey].length >= 2
      ) {
        return (
          user[this.firstNameKey].toUpperCase()[0] +
          user[this.firstNameKey].toUpperCase()[1]
        );
      } else if (
        user[this.firstNameKey] &&
        user[this.firstNameKey].length >= 1
      ) {
        return (
          user[this.firstNameKey].toUpperCase()[0] +
          user[this.firstNameKey].toUpperCase()[0]
        );
      } else if (user[this.lastNameKey] && user[this.lastNameKey].length >= 2) {
        return (
          user[this.lastNameKey].toUpperCase()[0] +
          user[this.lastNameKey].toUpperCase()[1]
        );
      } else if (user[this.lastNameKey] && user[this.lastNameKey].length >= 1) {
        return (
          user[this.lastNameKey].toUpperCase()[0] +
          user[this.lastNameKey].toUpperCase()[0]
        );
      } else if (
        user[this.businessNameKey] &&
        user[this.businessNameKey].length >= 1
      ) {
        return (
          user[this.businessNameKey].toUpperCase()[0] +
          user[this.businessNameKey].toUpperCase()[1]
        );
      }

      return "";
    },
  },
};
</script>
