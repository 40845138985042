/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@fortawesome/fontawesome-free/css/all.css";
import "@mdi/font/css/materialdesignicons.css";

import it from "vuetify/lib/locale/en";
import en from "vuetify/lib/locale/it";
import fr from "vuetify/lib/locale/fr";
import VCalendar from "v-calendar";

const vuetify = new Vuetify({
  theme: { disable: true },
  icons: {
    iconfont: "fa",
  },
});

Vue.use(Vuetify);
Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});
export default new Vuetify({
  rtl: false,
  lang: {
    locales: { it, en, fr },
    current: "en",
  },
});
