<template>
  <v-btn
    :class="getClass"
    :disabled="disabled"
    :color="backgroundColor"
    v-bind="{ ...$attrs }"
    v-on="$listeners"
    small
  >
    <span :style="getTextColor"
      ><slot></slot>
      <v-icon v-if="downarrow" right dark>mdi-arrow-down </v-icon></span
    >
  </v-btn>
</template>

<script>
export default {
  data() {
    return {};
  },

  watch: {},

  props: {
    danger: {
      type: Boolean,
      required: false,
      default: false,
    },

    warning: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    noMarginTop: {
      type: Boolean,
      required: false,
      default: false,
    },

    backgroundColor: {
      type: String,
      required: false,
      default: "#cb0c9f",
    },

    textColor: {
      type: String,
      required: false,
      default: "#ffffff",
    },

    secondary: {
      type: Boolean,
      required: false,
      default: false,
    },

    dimmed: {
      type: Boolean,
      required: false,
      default: false,
    },

    downarrow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    getClass() {
      let baseClass = this.$props.secondary
        ? this.$props.disabled
          ? "disabled"
          : "secondary"
        : this.$props.disabled
        ? "disabled"
        : "default";

      if (this.$props.dimmed && !this.$props.disabled) {
        return "font-weight-bolder btn-default py-4 px-8 bg-gradient-light";
      }

      if (this.$props.danger && !this.$props.disabled) {
        return "font-weight-bolder btn-default py-4 px-8 bg-gradient-danger";
      }

      if (this.$props.warning && !this.$props.disabled) {
        return "font-weight-bolder btn-default py-4 px-8 bg-gradient-warning";
      }

      return this.noMarginTop
        ? "font-weight-bolder btn-default py-4 px-8 bg-gradient-" + baseClass
        : "font-weight-bolder btn-default py-4 px-8 mt-2 mb-2 bg-gradient-" +
            baseClass;
    },

    getTextColor() {
      if (this.$props.dimmed) return "color : black";
      else return "color : " + this.$props.textColor;
    },
  },

  components: {},

  methods: {
    click($event) {
      this.$emit("click", $event);
    },
  },
};
</script>
