<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="date"
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <p
        class="text-left text-secondary text-caption ml-2 pb-0 pt-0 mb-0 mt-0"
        v-if="label"
      >
        {{ label }}
      </p>
      <FormTextInput
        @click:clear="onClear()"
        v-model="formattedDate"
        readonly
        v-bind="attrs"
        v-on="on"
        clearable
        :error-messages="errorMessages"
        :success="success"
        :background-color="getBackgroundColor"
        :disabled="disabled"
      ></FormTextInput>
      <p
        class="text-left text-primary text-caption ml-2 mb-2 mt-0"
        v-if="errorMessages[0]"
      >
        {{ errorMessages[0] ? errorMessages[0] : "" }}
      </p>
    </template>
    <v-date-picker color="#cb0c9f" v-model="date" scrollable>
      <v-spacer></v-spacer>
      <UniversalButton text color="#cb0c9f" secondary @click="dialogDismiss()"
        >Cancel
      </UniversalButton>
      <UniversalButton text color="#cb0c9f" @click="dialogConfirmed()"
        >OK
      </UniversalButton>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import dates from "@/services/dates";

export default {
  data() {
    return {
      modal: false,
      date: this.value,
    };
  },

  props: {
    value: {
      type: String,
      required: false,
    },

    errorMessages: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },

    success: {
      type: Boolean,
      required: false,
      default: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    label: {
      type: String,
      required: false,
      default: "",
    },

    locale: {
      type: String,
      required: false,
      default: "it",
    },
  },

  watch: {
    value: function (val) {
      if (!val) this.date = val;
    },
  },

  computed: {
    formattedDate: {
      // getter
      get() {
        return this.dateWithLocale();
      },
      // setter
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
        if (!newValue) this.date = null;
      },
    },

    getBackgroundColor() {
      if (this.disabled) return "rgba(240,240,240,.9)";
      return "rgba(255,255,255,.9)";
    },
  },

  mounted() {
    this.dateWithLocale();
  },

  methods: {
    dateWithLocale() {
      const d = dates.dateFromIsoToLocale(this.date, this.locale);
      return d;
    },

    validateDate() {
      const d = dates.validateDate(this.date, this.locale);
      return d;
    },

    dialogConfirmed() {
      this.$refs.dialog.save(this.date);
      // Convert date to locale date
      this.$emit("input", this.date);
    },

    dialogDismiss() {
      this.modal = false;
    },

    onClear() {
      this.$emit("input", null);
    },
  },
};
</script>
