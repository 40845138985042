<template>
  <SharedFooter />
</template>
<script>
import SharedFooter from "@/components/mockup/SharedFooter.vue";

export default {
  name: "unauth-footer",
  props: {
    auth: Boolean,
  },
  data() {
    return {};
  },

  components: {
    SharedFooter,
  },
};
</script>
