<template>
  <div>
    <v-row dense> </v-row>
    <v-row dense>
      <v-col class="text-left vuecal__custom__title_text">{{
        $refs.vuecal ? $refs.vuecal.viewTitle : ""
      }}</v-col>
    </v-row>
    <v-row justify="center" align="center" dense>
      <v-col class="vuecal__custom__title_btn_holder_primary text-left">
        <UniversalButton
          v-if="!$attrs['disableViews'].includes('day')"
          x-small
          class="ml-2 mr-2"
          @click="$refs.vuecal.switchView('day')"
          >{{ $t("calendar-view-day") }}</UniversalButton
        >

        <UniversalButton
          v-if="!$attrs['disableViews'].includes('week')"
          x-small
          class="ml-2 mr-2"
          @click="$refs.vuecal.switchView('week')"
          >{{ $t("calendar-view-week") }}</UniversalButton
        >
        <UniversalButton
          v-if="!$attrs['disableViews'].includes('month')"
          x-small
          class="ml-2 mr-2"
          @click="$refs.vuecal.switchView('month') == -1"
          >{{ $t("calendar-view-month") }}</UniversalButton
        >
        <UniversalButton
          v-if="!$attrs['disableViews'].includes('year')"
          x-small
          class="ml-2 mr-2"
          @click="$refs.vuecal.switchView('year') == -1"
          >{{ $t("calendar-view-year") }}</UniversalButton
        >
      </v-col>
      <v-col class="text-right">
        <v-btn class="mt-3" x-small text icon @click="$refs.vuecal.previous()"
          ><v-icon color="#8598ca">mdi-arrow-left</v-icon></v-btn
        >
        <v-btn
          class="mt-3"
          x-small
          text
          icon
          @click="$refs.vuecal.switchView('day', new Date())"
          ><v-icon color="#8598ca">mdi-target</v-icon></v-btn
        >
        <v-btn class="mt-3" x-small text icon @click="$refs.vuecal.next()"
          ><v-icon color="#8598ca">mdi-arrow-right</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <VueCal
      class="shared-calendar"
      hide-view-selector
      hide-title-bar
      ref="vuecal"
      v-bind="{ ...$attrs, ...commonAttrs }"
      v-on="$listeners"
      :locale="$store.state.user.locale"
      :time-cell-height="60"
      watchRealTime
    >
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        #[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" #[slotName]>
        <slot :name="slotName" />
      </template>
    </VueCal>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";

export default {
  inheritAttrs: false,

  computed: {
    commonAttrs() {
      return {};
    },
  },

  props: {},

  components: {
    VueCal,
  },
};
</script>

<style>
.shared-calendar .vuecal__custom__title_text {
  min-width: 100px;
  margin-left: 10px;
  margin-top: 10px;
  font-weight: bold;
}

.shared-calendar .vuecal__custom__title_btn {
  margin-left: 5px;
  margin-right: 5px;
  line-height: 2em;
}

.shared-calendar .vuecal__custom__title_btn_holder {
  min-width: 300px;
  margin-right: 10px;
}

.shared-calendar .vuecal__custom__title_btn_holder_primary {
  min-width: 300px;
}

.shared-calendar .vuecal__time-cell .hours.line:before {
  border-color: #42b983;
}

.shared-calendar .vuecal {
  background-color: white;
}

.shared-calendar .vuecal__menu,
.shared-calendar .vuecal__cell-events-count {
  background-color: transparent;
  border-radius: 2px;
}

.shared-calendar .vuecal__header button {
  background-color: #3a416f;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;
  color: white !important;
  border-bottom-color: #8598ca;
  font-size: 14px;
  margin: 2px;
}

.shared-calendar .vuecal__menu button {
  border-bottom-color: #8598ca;
  color: #fff;
  font-size: 1em;
}

.shared-calendar .vuecal__arrow--next {
  background-color: transparent !important;
}

.shared-calendar .vuecal__arrow--prev {
  background-color: transparent !important;
}

.shared-calendar .vuecal__arrow i.angle {
  color: white !important;
  margin: 2px;
  background-color: transparent !important;
}

.shared-calendar .vuecal__no-event {
  font-size: 12px;
}

.shared-calendar .weekday-label {
  font-size: 12px;
}

.shared-calendar .vuecal__time-column {
  font-size: 14px;
}

.shared-calendar .vuecal__menu button.active {
  background-color: rgba(255, 255, 255, 0.15);
}

.shared-calendar .vuecal__title-bar {
  background-color: #8598ca;
  border: 1px solid white;
  border-radius: 10px;
  color: white;
}

.shared-calendar .vuecal__cell.selected:before {
  border-color: rgb(181, 124, 75);
}

.shared-calendar .vuecal__event.availability {
  background-color: #83d0b184;
  border: 1px solid white;
  border-radius: 10px;
  color: #fff;
}

.shared-calendar .vuecal__event.appointment {
  background: rgb(145, 156, 188);
  border: none;
  border-radius: 10px;
  color: #fff;
}
.shared-calendar .vuecal__event.done {
  background: rgb(145, 156, 188);
  border-left: 10px solid rgb(210, 249, 113);
  border-radius: 10px;
  color: #fff;
}

.shared-calendar .vuecal__event.missing {
  background: rgb(145, 156, 188);
  border-left: 10px solid rgb(255, 90, 90);
  border-radius: 10px;
  color: #fff;
}

.shared-calendar .vuecal__event.void {
  background: rgb(145, 156, 188);
  border-left: 10px solid rgb(122, 106, 106);
  border-radius: 10px;
  color: #fff;
}

.shared-calendar .vuecal__cell-events-count {
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
}

.shared-calendar .vuecal--month-view .vuecal__cell {
  height: 200px;
}

.shared-calendar .vuecal--year-view .vuecal__cell {
  height: 100px;
}

.shared-calendar .vuecal--month-view .vuecal__cell-content {
  justify-content: flex-start;
  height: 100%;
  align-items: flex-end;
}

.shared-calendar .vuecal--month-view .vuecal__cell-date {
  padding: 4px;
}

.shared-calendar .vuecal--month-view .vuecal__no-event {
  display: none;
}

.shared-calendar .vuecal__cell--selected {
  background-color: #00000000;
}
.shared-calendar .vuecal__cell .cell-time-label {
  color: rgb(36, 36, 36);
  font-weight: 600;
}

.shared-calendar .vuecal__now-line {
  color: #8598ca;
}
</style>
