<template>
  <span>
    <p
      class="text-left text-secondary text-caption ml-2 pb-0 pt-0 mb-0 mt-0"
      v-if="label"
    >
      {{ label }}
    </p>

    <v-file-input
      ref="innerFileInput"
      v-bind="{ ...$attrs, ...commonAttrs }"
      v-on="$listeners"
    >
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        #[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" #[slotName]>
        <slot :name="slotName" />
      </template>
    </v-file-input>

    <p
      class="text-left text-primary text-caption ml-2 mb-2 mt-0"
      v-if="errorMessages[0]"
    >
      {{ errorMessages[0] ? errorMessages[0] : "" }}
    </p>
  </span>
</template>

<script>
export default {
  inheritAttrs: false,

  computed: {
    commonAttrs() {
      return {
        class: this.getClass,
        color: "rgba(0,0,0,.6)",
        outlined: true,
        light: true,
        hideDetails: true,
        truncateLength: "30",
        prependIcon: null,
        backgroundColor: this.getBackgroundColor,
      };
    },

    getBackgroundColor() {
      if (this.$attrs.disabled) return "rgba(240,240,240,.9)";
      return "rgba(255,255,255,.9)";
    },

    getClass() {
      let classes =
        "a font-size-input placeholder-lighter border border-radius-md text-light-input";
      if (this.label) classes += " mt-0";
      else if (!this.nomargin) classes += " mt-2";
      else classes += " mt-0";

      return classes;
    },
  },
  methods: {
    getComponentRef() {
      return this.$refs.innerFileInput;
    },
  },

  props: {
    label: {
      type: String,
      default: "",
    },

    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },

    nomargin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
