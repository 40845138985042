<template>
  <router-view></router-view>
</template>

<script>
export default {};
</script>

<style>
.profile-gravatar {
  width: 48px;
  height: 48px;
}

.profile-text {
  color: #ffffff;
  background-color: #bf126c;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
}

.profile-name {
  display: inline-block;
  align-items: center;
  margin-left: 5px;
}

.swal2-container {
  z-index: 999999;
}

.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #dddddd !important;
}
</style>
