const LocalStorageService = (function () {
  function _setContext(context) {
    localStorage.setItem("context", JSON.stringify(context));
  }

  function _getContext() {
    let context = localStorage.getItem("context");
    if (context) {
      try {
        context = JSON.parse(context);
      } catch (err) {
        context = null;
      }
    }

    return context;
  }

  function _setIdentity(identity) {
    localStorage.setItem("identity", JSON.stringify(identity));
  }

  function _getIdentity() {
    let identity = localStorage.getItem("identity");
    if (identity) {
      try {
        identity = JSON.parse(identity);
      } catch (err) {
        identity = null;
      }
    }

    return identity;
  }

  function _setSpoolerNumOfCopies(copies) {
    localStorage.setItem("spooler_num_of_copies", copies);
  }

  function _getSpoolerNumOfCopies() {
    let copies = localStorage.getItem("spooler_num_of_copies");
    if (!copies) copies = 1;
    return copies;
  }

  function _setToken(tokenObj) {
    localStorage.setItem("access_token", tokenObj.access_token);
    localStorage.setItem("refresh_token", tokenObj.refresh_token);
  }

  function _setAvatar(avatar) {
    if (avatar) localStorage.setItem("local_avatar", avatar);
    else localStorage.removeItem("local_avatar");
  }

  function _getAvatar() {
    return localStorage.getItem("local_avatar");
  }

  function _setNotifications(notifications) {
    if (notifications)
      localStorage.setItem("local_notifications", notifications);
    else localStorage.removeItem("local_notifications");
  }

  function _getNotifications() {
    return localStorage.getItem("local_notifications");
  }

  function _setLocale(locale) {
    if (locale) localStorage.setItem("locale", locale);
    else localStorage.removeItem("locale");
  }

  function _getLocale() {
    let locale = localStorage.getItem("locale");
    if (locale) return locale;
    else return "it";
  }

  function _getAccessToken() {
    return localStorage.getItem("access_token");
  }

  function _getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }

  function _clearToken() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  }

  function _clearAccessToken() {
    localStorage.removeItem("access_token");
  }

  function _clearData() {
    localStorage.removeItem("local_data");
  }

  function _getLocalData() {
    let data = {};
    try {
      let localData = localStorage.getItem("local_data");
      data = JSON.parse(localData);
    } catch (err) {
      data = {};
    }
    return data;
  }

  function _setLocalData(localData) {
    localStorage.setItem("local_data", JSON.stringify(localData));
  }

  function _setLocation(location) {
    localStorage.setItem("location", location);
  }

  function _getLocation() {
    let data = 0;
    try {
      data = localStorage.getItem("location");
    } catch (err) {
      data = 0;
    }
    return parseInt(data);
  }

  return {
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    clearAccessToken: _clearAccessToken,
    clearData: _clearData,
    getAvatar: _getAvatar,
    setAvatar: _setAvatar,
    getNotifications: _getNotifications,
    setNotifications: _setNotifications,
    setLocalData: _setLocalData,
    getLocalData: _getLocalData,
    getLocale: _getLocale,
    setLocale: _setLocale,
    setSpoolerNumOfCopies: _setSpoolerNumOfCopies,
    getSpoolerNumOfCopies: _getSpoolerNumOfCopies,
    getContext: _getContext,
    setContext: _setContext,
    getIdentity: _getIdentity,
    setIdentity: _setIdentity,
    setLocation: _setLocation,
    getLocation: _getLocation,
  };
})();

export default LocalStorageService;
