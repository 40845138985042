import axios from "axios";
import config from "@/config";

let notifications = {
  fetchNotifications: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/notifications/list",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            notifications: resp.data.responseData.notifications,
            count: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addNotification: (notification) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/notifications/add",
        data: { notification: notification },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateNotification: (notification) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/notifications/update",
        data: { notification: notification },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeNotification: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/notifications/delete",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  ackNotification: (id, remove) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/notifications/ack",
        data: { id: id, remove: remove },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default notifications;
