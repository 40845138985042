<template>
  <span>
    <p
      class="text-left text-secondary text-caption ml-2 pb-0 pt-0 mb-0 mt-0"
      v-if="label"
    >
      {{ label }}
    </p>

    <v-row>
      <v-col :lg="selectColumns" :md="selectColumns" :cols="selectColumns">
        <v-select v-bind="{ ...$attrs, ...commonAttrs }" v-on="$listeners">
          <template
            v-for="(_, scopedSlotName) in $scopedSlots"
            #[scopedSlotName]="slotData"
          >
            <slot :name="scopedSlotName" v-bind="slotData" />
          </template>
          <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
          </template>
        </v-select>
      </v-col>
      <v-col v-if="hasAppendOutside" lg="1" md="1" cols="1">
        <slot name="appendOutside"></slot>
      </v-col>
    </v-row>

    <p
      class="text-left text-primary text-caption ml-2 mb-2 mt-0"
      v-if="errorMessages[0]"
    >
      {{ errorMessages[0] ? errorMessages[0] : "" }}
    </p>
  </span>
</template>

<script>
export default {
  inheritAttrs: false,

  computed: {
    selectColumns() {
      if (this.hasAppendOutside) return 11;
      else return 12;
    },

    hasAppendOutside() {
      return !!this.$slots["appendOutside"];
    },

    commonAttrs() {
      return {
        class:
          "input-style font-size-input text-light-input placeholder-light border border-radius-md select-style mt-0 mb-2",
        outlined: true,
        singleLine: true,
        hideDetails: true,
        backgroundColor: this.getBackgroundColor,
        style: this.errored ? "border:1px solid red;" : "",
      };
    },

    getBackgroundColor() {
      if (this.$attrs.disabled) return "rgba(240,240,240,.9)";
      return "rgba(255,255,255,.9)";
    },
  },

  props: {
    label: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    errored: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>

<style>
.v-input__prepend-outer,
.v-input__append-outer {
  margin-top: 8px !important;
}
</style>
