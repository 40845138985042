<template>
  <v-container>
    <v-row class="justify-center d-flex mt-n16">
      <v-col lg="4" md="7" class="mx-auto">
        <v-card class="card-shadow border-radius-xl">
          <div class="card-padding text-center">
            <v-row class="d-flex">
              <v-col class="text-right">
                <v-img
                  v-if="$store.state.identity.logo_file"
                  class="mx-auto"
                  max-width="128"
                  max-height="128"
                  :src="
                    'data:image/png;base64,' + $store.state.identity.logo_file
                  "
                ></v-img
              ></v-col>
            </v-row>
          </div>

          <div class="mt-2 position-relative text-center">
            <p
              class="text-sm font-weight-bold mb-2 text-secondary short-text-border text-border d-inline z-index-2 bg-white px-3"
            >
              {{ $t("login-subtitle") }}
            </p>
          </div>
          <v-form @submit.prevent="login(false)" id="login-form">
            <ValidationObserver immediate ref="obs" v-slot="{ invalid }">
              <form>
                <div class="card-padding">
                  <v-row dense>
                    <v-col>
                      <ValidationProvider
                        rules="required"
                        v-slot="{ errors, valid }"
                      >
                        <FormTextInput
                          v-on:keyup.enter="login(false)"
                          :disabled="logging"
                          v-model="email"
                          :error-messages="errors"
                          :success="valid"
                        >
                        </FormTextInput>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <ValidationProvider
                        rules="required"
                        v-slot="{ errors, valid }"
                      >
                        <FormTextInput
                          v-on:keyup.enter="login(false)"
                          :disabled="logging"
                          outlined
                          v-model="password"
                          :error-messages="errors"
                          :success="valid"
                          autocomplete="off"
                          :append-icon="
                            password_view ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          @click:append="() => (password_view = !password_view)"
                          :type="password_view ? 'password' : 'text'"
                        >
                        </FormTextInput>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <div class="text-center">
                    <UniversalButton
                      :disabled="invalid"
                      v-if="!logging"
                      @click="login(false)"
                    >
                      {{ $t("login-button") }}
                    </UniversalButton>
                  </div>
                  <p
                    v-if="logging"
                    class="text-center text-sm text-body mb-0 mt-2"
                  >
                    <v-progress-circular
                      class="mb-2"
                      :indeterminate="true"
                      :rotate="0"
                      :size="32"
                      :width="4"
                      color="#3A416F"
                    ></v-progress-circular>
                  </p>

                  <div class="mt-2" v-if="loginError">
                    <v-alert
                      close-icon="fas fa-times font-size-root text-white"
                      class="alert alert-danger"
                      type="error"
                      dense
                      dismissible
                    >
                      <span class="text-uppercase" v-text="loginError" />
                    </v-alert>
                  </div>

                  <p
                    v-if="!logging"
                    class="text-center text-sm text-body mt-3 mb-0"
                  >
                    {{ $t("login-lost-password") }}<br />

                    <router-link
                      class="text-dark text-decoration-none font-weight-bolder"
                      to="/recovery"
                    >
                      {{ $t("login-lost-password-link") }}
                    </router-link>
                  </p>
                </div>
              </form>
            </ValidationObserver>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userManager from "@/apis/users";
import { solveLoginChallenge } from "@webauthn/client";
import vee from "@/plugins/vee-validate.js";
import staticData from "@/apis/static";

export default {
  data() {
    return {
      logging: false,
      email: "",
      password: "",
      loginError: "",
      password_view: true,
      login_mode: 1, // 1 - email/pwd , 2 - qr-code , 3 - AD if available
      otp_token: null,
      key_credentials: null,
      fa_sms_id: null,
    };
  },

  mounted() {
    this.logging = false;
    this.$refs.obs.reset();
    window.login = this.$options.methods.login.bind(this);
  },

  computed: {
    getQrCodeStyle() {
      if (this.qrCodeInitInProgress)
        return "margin: auto; margin-top:10px; width:0px; height:0px;";
      else return "margin: auto; margin-top:10px; width:100px; height:100px;";
    },
  },

  methods: {
    askForOtp: function (sms, sms_active) {
      return new Promise((resolve, reject) => {
        this.$swal({
          title: "<strong>" + this.$t("login-2fa-enabled") + "</strong>",
          html: !sms
            ? sms_active
              ? "<p style='cursor:pointer; color: #bf126c' onclick='window.login(true)'>" +
                this.$t("login-2fa-lost-go-sms") +
                "</p>"
              : this.$t("login-2fa-lost-no-way")
            : "",
          showCloseButton: true,
          showCancelButton: false,
          customClass: {
            confirmButton: "bg-gradient-success px-4 py-4 border-radius-md",
            cancelButton: "bg-gradient-danger px-4 py-4 border-radius-md",
            input:
              "font-size-input placeholder-lighter border border-radius-md mt-2 otp-code-field",
          },
          confirmButtonText: this.$t("gbl-confirm"),
          input: "text",
          inputLabel: sms ? this.$t("login-2fa-sms") : this.$t("login-2fa-app"),
          inputAttributes: {
            input: "number",
            required: "true",
          },

          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value.length == 6) {
                resolve();
              } else {
                resolve(this.$t("login-2fa-validate"));
              }
            });
          },
        })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    login: function (force_sms) {
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let email = this.email;
      let password = this.password;
      let otp_token = this.otp_token;
      let key_credentials = this.key_credentials;
      let fa_sms_id = this.fa_sms_id;
      this.loginError = "";
      this.logging = true;
      this.$store
        .dispatch("login", {
          email,
          password,
          otp_token,
          fa_sms_id,
          force_sms,
          key_credentials,
          timezone,
        })
        .then((res) => {
          if (res.request_key) {
            userManager
              .requestAuthNLogin(email)
              .then(async (challenge) => {
                // Request key
                const credentials = await solveLoginChallenge(challenge);
                if (credentials) {
                  this.key_credentials = credentials;
                  this.login();
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else if (res.request_app_otp) {
            this.otp_token = null;
            this.askForOtp(false, res.fa_sms_active)
              .then((res) => {
                if (res.isConfirmed) {
                  this.otp_token = res.value;
                  this.login(force_sms);
                } else {
                  this.loginError = null;
                  this.logging = false;
                  this.otp_token = null;
                }
              })
              .catch((err) => {
                console.log(err);
                this.otp_token = null;
              });
          } else if (res.request_sms_otp) {
            this.fa_sms_id = res.fa_sms_id;
            this.askForOtp(true)
              .then((res) => {
                if (res.isConfirmed) {
                  this.otp_token = res.value;
                  this.login(force_sms);
                } else {
                  this.loginError = null;
                  this.logging = false;
                  this.otp_token = null;
                }
              })
              .catch((err) => {
                this.otp_token = null;
                console.log(err);
              });
          } else {
            this.$store
              .dispatch("fetchStaticData")
              .then(() => {
                this.$i18n.locale = this.$store.state.user.locale;
                vee.setupLocale(); // Required to regenerate the locales in the custom messages
                staticData.update();
                this.$router.push(this.$store.state.user.landing_page);
              })
              .catch((err) => {
                this.loginError = err;
                this.otp_token = null;
                this.logging = false;
              });
          }
        })
        .catch((err) => {
          this.loginError = err;
          this.logging = false;
          this.otp_token = null;
        });
    },
  },
};
</script>

<style>
.otp-code-field {
  max-width: 100px;
  margin: auto;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
}
</style>
