import axios from "axios";
import config from "@/config";

let identity = {
  fetchLocations: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/fetchLocations",
        data: {},
        method: "POST",
      })
        .then((res) => {
          resolve({ locations: res.data.responseData.locations });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchBalance: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/fetchBalance",
        data: {},
        method: "POST",
      })
        .then((res) => {
          resolve({ balance: res.data.responseData.balance });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchOptionsValues: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/fetchOptionsValues",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          let opts = {};
          for (let k in resp.data.responseData) {
            let opt = resp.data.responseData[k];
            opts[opt.option_key] = opt.option_value;
          }
          resolve(opts);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateFunctionalSettings: (data) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updateFunctionalSettings",
        data: data,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateLayoutSettings: (data) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updateLayoutSettings",
        data: data,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateLetterheadSettings: (data, locale) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updateLetterheadSettings",
        data: { data: data, locale: locale },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateIntegrations: (data) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updateIntegrations",
        data: data,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateMailSettings: (data) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updateMailSettings",
        data: data,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateCertifiedMailSettings: (data) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updateCertifiedMailSettings",
        data: data,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateAppsSettings: (data) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updateAppsSettings",
        data: data,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateMessagesSettings: (data) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updateMessagesSettings",
        data: data,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateBankingInformations: (data, locale) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updateBankingInformations",
        data: { data: data, locale: locale },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateReaInformations: (data) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updateReaInformations",
        data: data,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateContactInformations: (data, locale) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updateContactInformations",
        data: { data: data, locale: locale },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updatePortalsInformations: (data, locale) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updatePortalsInformations",
        data: { data: data, locale: locale },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateReportSettings: (data, locale) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updateReportSettings",
        data: { data: data, locale: locale },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateExposureSettings: (data, locale) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updateExposureSettings",
        data: { data: data, locale: locale },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateOrdersSettings: (data, locale) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updateOrdersSettings",
        data: { data: data, locale: locale },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateWithdrawalsSettings: (data, locale) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updateWithdrawalsSettings",
        data: { data: data, locale: locale },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updatePricelistSettings: (data, locale) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updatePricelistSettings",
        data: { data: data, locale: locale },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateInvoicesSettings: (data, locale) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updateInvoicesSettings",
        data: { data: data, locale: locale },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateProformasSettings: (data, locale) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updateProformasSettings",
        data: { data: data, locale: locale },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updatePrivacySettings: (data, locale) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updatePrivacySettings",
        data: { data: data, locale: locale },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateFiscalInformations: (data) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/identity/updateFiscalInformations",
        data: data,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default identity;
