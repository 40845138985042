<template>
  <span>
    <p
      class="text-left text-secondary text-caption ml-2 pb-0 pt-0 mb-0 mt-0"
      v-if="label"
    >
      {{ label }}
    </p>

    <v-text-field
      color="rgba(0,0,0,.6)"
      hide-details
      outlined
      light
      :class="getClass"
      :background-color="getBackgroundColor"
      style="margin-top: 0px !important"
      v-bind="{ ...$attrs, ...commonAttrs }"
      v-on="$listeners"
    >
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        #[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" #[slotName]>
        <slot :name="slotName" />
      </template>
    </v-text-field>

    <p
      class="text-left text-primary text-caption ml-2 mb-2 mt-0"
      v-if="errorMessages[0]"
    >
      {{ errorMessages[0] ? errorMessages[0] : "" }}
    </p>
  </span>
</template>

<script>
export default {
  inheritAttrs: false,

  computed: {
    commonAttrs() {
      return {
        class: this.getClass,
        outlined: true,
        light: true,
        hideDetails: true,
        backgroundColor: this.getBackgroundColor,
      };
    },

    getBackgroundColor() {
      if (this.$attrs.disabled) return "rgba(240,240,240,.9)";
      return "rgba(255,255,255,.9)";
    },

    getClass() {
      let classes =
        "a font-size-input placeholder-lighter border border-radius-md text-light-input";
      if (this.label) classes += " mt-0";
      else classes += " mt-2";

      return classes;
    },
  },

  props: {
    errorMessages: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },

    label: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
