<template>
  <v-app-bar
    :color="background"
    height="auto"
    class="elevation-3 mt-5 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none"
    :class="
      navbarFixed
        ? 'position-sticky blur shadow-blur top-1 z-index-sticky py-2'
        : ''
    "
    flat
  >
    <transactions ref="transactions" />
    <v-row class="py-1">
      <v-col cols="12" sm="8" class="d-flex align-center">
        <div
          class="drawer-toggler mr-2 cursor-pointer"
          @click="minifyDrawer"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
          </div>
        </div>

        <div>
          <Avatar
            ref="avatar"
            :editable="false"
            :email="$store.state.user.email"
          />

          <span style="display: inline-block" class="ml-2 header-welcome">
            <v-row>
              <v-col>
                {{
                  $store.state.user.gender == "M"
                    ? $t("labstream-welcome-male", {
                        name: $store.state.user.first_name,
                      })
                    : $t("labstream-welcome-female", {
                        name: $store.state.user.first_name,
                      })
                }}
                <p class="mb-0 font-weight-bold text-body text-sm">
                  {{
                    $t("header-welcome", {
                      notifications:
                        $store.state &&
                        $store.state.user &&
                        $store.state.user.notifications
                          ? $store.state.user.notifications.length
                          : 0,
                      last_login:
                        $store.state &&
                        $store.state.user &&
                        $store.state.user.last_login
                          ? $options.filters.UTCToLocalTime(
                              $store.state.user.last_login
                            )
                          : 0,
                      last_login_ip:
                        $store.state &&
                        $store.state.user &&
                        $store.state.user.last_login_ip
                          ? $store.state.user.last_login_ip
                          : "",
                    })
                  }}
                </p>
              </v-col>
            </v-row>
          </span>
        </div>
      </v-col>

      <v-col cols="12" sm="4" class="d-flex align-center justify-end">
        <v-btn
          elevation="0"
          height="43"
          class="font-weight-600 text-capitalize drawer-toggler py-3 px-0 rounded-sm"
          v-if="$vuetify.breakpoint.mobile"
          color="transparent"
          @click="drawerClose"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line text-body"></i>
            <i class="drawer-toggler-line text-body"></i>
            <i class="drawer-toggler-line text-body"></i>
          </div>
        </v-btn>
        <v-tooltip bottom v-if="$store.state.isAdmin()"
          ><template v-slot:activator="{ on }"
            ><v-btn
              class="mr-1"
              small
              text
              icon
              @click.stop="openTransactions()"
              v-on="on"
              ><v-icon size="24" class="text-body mr-2"
                >mdi-file-document-multiple-outline</v-icon
              ></v-btn
            ></template
          ><span>{{ $t("topbar-balance-transactions") }}</span></v-tooltip
        >
        <v-tooltip v-if="$store.state.isAdmin()" bottom>
          <template v-slot:activator="{ on }">
            <UniversalButton
              @click="recharge()"
              :disabled="loading_balance"
              v-if="$store.state.balance >= 50"
              v-on="on"
              class="mr-2"
              >{{
                $t("topbar-balance-recharge") +
                " " +
                $store.state.balance.toFixed(2) +
                " €"
              }}</UniversalButton
            >
            <UniversalButton
              @click="recharge()"
              :disabled="loading_balance"
              warning
              v-else-if="$store.state.balance > 0"
              v-on="on"
              class="mr-2"
              >{{
                $t("topbar-balance-recharge") +
                " " +
                $store.state.balance.toFixed(2) +
                " €"
              }}</UniversalButton
            >
            <UniversalButton
              @click="recharge()"
              :disabled="loading_balance"
              danger
              v-else
              v-on="on"
              class="mr-2"
              >{{
                $t("topbar-balance-recharge") +
                " " +
                $store.state.balance.toFixed(2) +
                " €"
              }}</UniversalButton
            > </template
          ><span v-if="$store.state.balance < 50 && $store.state.balance > 0">{{
            $t("topbar-balance-running-low")
          }}</span
          ><span v-else-if="$store.state.balance <= 0">{{
            $t("topbar-balance-running-expired")
          }}</span
          ><span v-else>{{ $t("topbar-balance-recharge") }}</span>
        </v-tooltip>

        <FormSelect
          v-if="!$route.meta.disableLocation"
          :disabled="loading_locations"
          :items="locations"
          item-text="name"
          item-value="id"
          class="mr-5 mt-2"
          v-model="location"
          @change="locationChanged"
          hide-details
        />

        <v-menu
          transition="slide-y-transition"
          offset-y
          offset-x
          min-width="300"
          max-width="640"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              v-if="
                $store.state &&
                $store.state.user &&
                $store.state.user.notifications &&
                $store.state.user.notifications.length > 0
              "
              :content="$store.state.user.notifications.length"
              overlap
              bordered
              color="#bf126c"
            >
              <v-btn
                icon
                :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                class="text-body"
                :color="linkColor"
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon size="24">mdi-bell</v-icon>
              </v-btn>
            </v-badge>
          </template>

          <v-list v-if="$store.state.user" class="py-0">
            <v-list-item
              v-for="(item, i) in $store.state.user.notifications"
              :key="i"
              class="pa-4 list-item-hover-active"
            >
              <v-list-item-avatar
                :size="36"
                class="my-0 me-4 border-radius-lg"
                v-if="item.avatar_id"
              >
                <UniversalListAvatar :item="item" id-key="avatar_id" />
              </v-list-item-avatar>

              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                >
                  <v-row>
                    <v-col>
                      <h6 class="text-sm font-weight-normal mb-1 text-typo">
                        {{ item.content }}
                      </h6>
                    </v-col>
                  </v-row>
                </v-list-item-title>

                <p class="text-xs text-secondary mb-0">
                  <v-icon size="12" class="text-secondary">mdi-clock</v-icon>
                  {{ item.creation_date }}
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-tooltip v-if="!$store.state.spooler_online" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon size="24" class="text-body ml-2" v-bind="attrs" v-on="on">
              mdi-printer-off
            </v-icon>
          </template>
          <span>{{ $t("topbar-spooler-online") }}</span>
        </v-tooltip>

        <v-menu
          bottom
          left
          offset-y
          origin="top right"
          transition="scale-transition"
          v-if="$store.state.spooler_online"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
              <v-badge color="orange" overlap bordered>
                <template v-slot:badge>
                  <span>{{ $store.state.spooler_num_of_copies }}</span>
                </template>

                <v-icon color="#6f8b3a" size="24">mdi-printer-check</v-icon>
              </v-badge>
            </v-btn>
          </template>

          <v-list :tile="false" nav>
            <div>
              <v-list-item
                v-for="(n, i) in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                :key="`item-${i}`"
              >
                <v-list-item-title @click="selectPrinterSpoolCopies(n)">{{
                  n
                }}</v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>

        <v-menu
          bottom
          left
          min-width="200"
          offset-y
          origin="top right"
          transition="scale-transition"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              icon
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              class="text-body ml-2"
              :color="linkColor"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon size="24">mdi-account</v-icon>
            </v-btn>
          </template>

          <v-list :tile="false" flat nav>
            <template v-for="(p, i) in profile">
              <v-divider
                v-if="p.divider"
                :key="`divider-${i}`"
                class="mb-2 mt-2"
              />
              <v-list-item v-else :key="`item-${i}`">
                <v-list-item-title
                  class="pa-4 list-item-hover-active"
                  v-text="p.title"
                  @click="handleClick(p)"
                />
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
const packageInfo = require("../../../package.json");

import Avatar from "./Avatar";
import AvatarServices from "@/services/avatars";
import notificationsManager from "@/apis/notifications";
import identityManager from "@/apis/identity";
import localStorageService from "@/services/localStorageService";
import { EventBus } from "@/plugins/bus";
import transactions from "@/components/framework/Transactions.vue";

export default {
  name: "app-bar",
  props: {
    background: String,
    linkColor: String,
    toggleActive: Boolean,
  },
  data() {
    return {
      mini: false,
      packageInfo: packageInfo,
      navbarFixed: true,
      hasBg: true,
      drawer: false,
      togglerActive: false,
      profile: [],
      notification_snackbar: false,
      notification_timeout: 60000,
      notification: null,
      notification_severity: "primary",
      incCounterRecomputer: 0,
      loading_locations: false,
      loading_balance: false,
      location: this.$store.state.current_location,
      locations: [],
    };
  },

  components: {
    Avatar,
    transactions,
  },

  mounted() {
    this.profile = [{ title: this.$t("header-profile"), url: "/profile" }];
    let modules = this.$store.state.frontend.enabled_modules.split(",");
    if (
      modules.includes("calendar") &&
      ((this.$store.state.user.role == 1 &&
        (this.$store.state.hasRight("244") ||
          this.$store.state.hasRight("235"))) ||
        this.$store.state.isAdmin())
    )
      // Has doctor role
      this.profile.push({
        title: this.$t("header-availability"),
        url: "/personal-agenda-availability",
      });

    this.profile = [
      ...this.profile,
      { divider: true },
      { title: this.$t("header-logout"), func: this.logout },
    ];
    EventBus.$on("avatar-changed", this.avatarUpdater);
    this.loading_locations = true;
    this.loading_balance = true;
    this.fetchBalance().then(() => {
      this.fetchLocations().then((res) => {
        this.locations = res;
      });
    });
  },

  methods: {
    openTransactions() {
      this.$refs.transactions.show();
    },

    recharge() {},

    locationChanged() {
      // Commit current location
      this.$store
        .dispatch("currentLocationChanged", {
          location: this.location,
        })
        .then(() => {
          this.$router.go(0);
        });
    },

    fetchLocations() {
      this.loading_locations = true;
      return new Promise((resolve, reject) => {
        identityManager
          .fetchLocations()
          .then((results) => {
            this.loading_locations = false;
            resolve(results.locations);
          })
          .catch((err) => {
            this.loading_locations = false;
            console.log(err);
            reject(err);
          });
      });
    },

    fetchBalance() {
      this.loading_balance = true;
      return new Promise((resolve, reject) => {
        identityManager
          .fetchBalance()
          .then((results) => {
            this.loading_balance = false;
            this.$store.state.balance = results.balance;
            resolve(results.balance);
          })
          .catch((err) => {
            this.loading_balance = false;
            console.log(err);
            reject(err);
          });
      });
    },

    avatarUpdater() {
      if (this.$refs.avatar) this.$refs.avatar.loadAvatar();
    },

    drawerClose() {
      this.togglerActive = !this.togglerActive;
      this.$emit("drawer-toggle", true);
    },

    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
    selectPrinterSpoolCopies(n) {
      this.$store.dispatch("spoolerSetNumOfCopies", { copies: n });
    },

    buildGravatar: function (notification) {
      return (
        "<img style='width:48px; border:1px solid black; border-radius: 50%;' src='data:image/jpeg;base64," +
        notification.gravatar +
        "'>"
      );
    },

    checkGravatar: function (notification) {
      return new Promise((resolve, reject) => {
        if (!notification.gravatarFetched) {
          notification.gravatarFetched = true;
          AvatarServices.fetchAvatar(notification.avatar_id)
            .then((result) => {
              notification.gravatar = result.avatar;
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          resolve();
        }
      });
    },

    handleClick: function (item) {
      if (item.func) item.func();
      else if (item.url) {
        if (item.url != this.$router.currentRoute.path)
          this.$router.push(item.url);
      }
    },

    closeNotification() {
      this.notification_snackbar = false;
      this.notification = null;
    },

    ackNotification() {
      notificationsManager
        .ackNotification(this.notification.id, false)
        .then(() => {
          this.notification.ref.acked = true;
          localStorageService.setNotifications(
            JSON.stringify(this.$store.state.user.notifications)
          );
          this.incCounterRecomputer++;
          this.closeNotification();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    removeNotification() {
      notificationsManager
        .ackNotification(this.notification.id, true)
        .then(() => {
          for (
            let n = 0;
            n < this.$store.state.user.notifications.length;
            n++
          ) {
            if (
              this.$store.state.user.notifications[n].id == this.notification.id
            ) {
              this.$store.state.user.notifications.splice(n, 1);
              break;
            }
          }

          localStorageService.setNotifications(
            JSON.stringify(this.$store.state.user.notifications)
          );
          this.incCounterRecomputer++;
          this.closeNotification();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    showNotification(notification) {
      this.notification_snackbar = true;
      this.notification = notification;
      if (notification.severity == 0) this.notification_severity = "orange";
      else if (notification.severity == 1)
        this.notification_severity = "purple";
      else if (notification.severity == 2)
        this.notification_severity = "#6f8b3a";
    },

    processNotification(notification) {
      if (!notification.valid) return;
      if (notification.avatar_id) {
        this.checkGravatar(notification)
          .then(() => {
            this.showNotification(notification);
          })
          .catch((err) => {
            console.log(err);
            this.showNotification(notification);
          });
      } else {
        this.showNotification(notification);
      }
    },

    hasValidNotifications: function () {
      if (
        !this.notifications ||
        !this.notifications.length ||
        !this.notifications[0].valid
      )
        return false;
      else return true;
    },

    logout: function () {
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$router.push("/login").catch(() => {});
        })
        .catch((err) => {
          console.log(err);
          this.$router.push("/login").catch(() => {});
        });
    },
  },

  computed: {
    notifications: function () {
      this.incCounterRecomputer;
      if (
        this.$store.state.user.notifications &&
        this.$store.state.user.notifications.length
      ) {
        let not = [];
        for (let n = 0; n < this.$store.state.user.notifications.length; n++) {
          let ntf = this.$store.state.user.notifications[n];
          not.push({ ...ntf, title: ntf.name, valid: true, ref: ntf });
        }
        return not;
      } else {
        return [{ title: this.$t("header-nonotifications"), valid: false }];
      }
    },

    notificationBadgeNumber: function () {
      let n = 0;
      for (let x = 0; x < this.notifications.length; x++) {
        if (this.notifications[x].valid && !this.notifications[x].ref.acked)
          n++;
      }
      return n;
    },
  },
  watch: {
    toggleActive(val) {
      this.togglerActive = val;
    },
  },
};
</script>
