<template>
  <div>
    <v-row dense>
      <v-checkbox
        hide-details
        color="rgba(0,0,0,.6)"
        v-model="modelProp"
        :disabled="disabled"
        :class="getClass"
        @change="handlerChanged"
        :false-value="falseValue"
        :true-value="trueValue"
      >
      </v-checkbox>
      <span
        class="text-left text-secondary text-caption"
        style="padding-top: 6px"
        v-if="label"
      >
        {{ label }}
      </span>
    </v-row>
    <p
      class="text-left text-primary text-caption ml-2 mb-0 mt-0"
      v-if="errorMessages[0]"
    >
      {{ errorMessages[0] ? errorMessages[0] : "" }}
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return { content: this.value };
  },

  watch: {},

  computed: {
    modelProp: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },

    getBackgroundColor() {
      if (this.disabled) return "rgba(240,240,240,.9)";
      return "rgba(255,255,255,.9)";
    },

    getClass() {
      let classes = "a font-size-input placeholder-lighter  text-light-input";
      if (this.label) classes += " mt-0";
      else classes += " mt-4";
      return classes;
    },
  },

  props: {
    value: {
      required: false,
    },

    falseValue: {
      required: false,
      default: undefined,
    },

    trueValue: {
      required: false,
      default: undefined,
    },

    errorMessages: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },

    label: {
      type: String,
      required: false,
      default: null,
    },

    valid: {
      type: Boolean,
      required: false,
      default: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    handlerChanged(/*e*/) {
      this.$emit("change", this.content);
    },
  },
};
</script>
