<template>
  <span>
    <v-dialog
      overlay-opacity="0.1"
      persistent
      v-model="cropperDialog"
      max-width="500px"
    >
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0">{{
            $t("profile-pick-avatar-image")
          }}</span>
        </div>
        <v-card-text class="pa-0 text-center card-border-bottom">
          <div v-show="croppieVisible">
            <v-btn small text icon @click="rotate(-90)"
              ><v-icon>mdi-rotate-right</v-icon></v-btn
            >
            <v-btn small text icon @click="rotate(90)"
              ><v-icon>mdi-rotate-left</v-icon></v-btn
            >
            <vue-croppie
              ref="croppieRef"
              :enableOrientation="true"
              :enable-resize="false"
              :boundary="{ width: 128, height: 128 }"
              :viewport="{ width: 128, height: 128, type: 'square' }"
            />
          </div>

          <img v-bind:src="croppedAvatar" />

          <v-progress-circular
            v-if="(cropping || !croppieVisible) && !croppedAvatar"
            :indeterminate="true"
            :rotate="0"
            :size="32"
            :width="4"
            color="#3A416F"
          ></v-progress-circular>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            :disabled="!croppieVisible || cropping"
            elevation="0"
            @click="closeCrop()"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary py-4 px-8"
            :class="
              'bg-gradient-' +
              (!croppieVisible || cropping ? 'disabled' : 'light')
            "
            >{{ $t("gbl-cancel") }}</v-btn
          >

          <v-btn
            :disabled="!croppieVisible || cropping"
            @click="crop()"
            elevation="0"
            height="43"
            :class="
              'bg-gradient-' +
              (!croppieVisible || cropping ? 'disabled' : 'default')
            "
            class="font-weight-bolder btn-default py-4 px-8 ms-auto mt-sm-auto mt-4"
            >{{ $t("gbl-ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ImageUpload v-if="editable" v-model="avatarLoader">
      <span slot="activator">
        <v-btn
          width="74"
          height="74"
          v-ripple="false"
          icon
          :disabled="!editable"
        >
          <v-avatar
            width="74"
            height="74"
            class="shadow border-radius-lg profile-text"
            v-ripple="editable"
          >
            <img v-if="croppedAvatar" :src="croppedAvatar" />
            <span v-if="!hasGravatar && !croppedAvatar">{{
              getProfileLetters
            }}</span>
            <img v-if="hasGravatar && !croppedAvatar" :src="getGravatar()" />
            <div v-if="editable" class="avatar_camera">
              <v-icon color="#aaaaaa" size="">mdi-camera</v-icon>
            </div>
          </v-avatar>
        </v-btn>
      </span>
    </ImageUpload>

    <v-avatar
      v-if="!editable"
      width="74"
      height="74"
      class="shadow border-radius-lg profile-text"
      v-ripple="editable"
    >
      <img v-if="croppedAvatar" :src="croppedAvatar" />
      <span v-if="!hasGravatar && !croppedAvatar">{{ getProfileLetters }}</span>
      <img v-if="hasGravatar && !croppedAvatar" :src="getGravatar()" />
      <div v-if="editable" class="avatar_camera">
        <v-icon color="#aaaaaa" size="">mdi-camera</v-icon>
      </div>
    </v-avatar>
  </span>
</template>

<script>
import avatars from "@/services/avatars";
import ImageUpload from "@/../../shared/app/components/gui/ImageUpload";
import config from "@/config";
import userManager from "@/apis/users";
import localStorageService from "@/services/localStorageService";
import { EventBus } from "@/plugins/bus";

export default {
  components: {
    ImageUpload,
  },

  props: {
    editable: Boolean,
    email: String,
  },

  data() {
    return {
      gravatar: null,
      avatarLoader: null,
      savingAvatar: false,
      savedAvatar: false,

      cropperDialog: false,
      cropperDialogURL: false,
      croppedAvatar: null,
      croppieVisible: false,
      cropping: false,

      avatarChanged: false,
      gravatarMode: null,
    };
  },

  computed: {
    getProfileLetters: function () {
      let first_name = this.$store.state.user.first_name;
      let last_name = this.$store.state.user.last_name;

      if (first_name && last_name) {
        if (first_name.length && last_name.length) {
          return first_name.toUpperCase()[0] + last_name.toUpperCase()[0];
        } else if (first_name && first_name.length >= 2) {
          return first_name.toUpperCase()[0] + first_name.toUpperCase()[1];
        } else if (first_name && first_name.length >= 1) {
          return first_name.toUpperCase()[0] + first_name.toUpperCase()[0];
        } else if (last_name && last_name.length >= 2) {
          return last_name.toUpperCase()[0] + last_name.toUpperCase()[1];
        } else if (last_name && last_name.length >= 1) {
          return last_name.toUpperCase()[0] + last_name.toUpperCase()[0];
        }
      }
      return "";
    },

    hasGravatar: function () {
      return this.gravatar ? true : false;
    },
  },

  mounted() {
    this.loadAvatar();
  },

  methods: {
    loadAvatar() {
      this.gravatar = this.$store.state.user.avatar;
      this.gravatarMode = this.$store.state.user.gravatar;
      this.checkAvatar()
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },

    onClickAvatar() {
      if (this.editable) this.avatarLoader = {};
    },

    getGravatarMode() {
      return this.gravatarMode;
    },

    checkAvatar() {
      return new Promise((resolve) => {
        if (!this.croppedAvatar && this.gravatarMode == true) {
          clearTimeout(this._debounceTimer);
          this._debounceTimer = setTimeout(() => {
            avatars
              .fetchGravatar(this.email)
              .then((result) => {
                this.gravatar = result;
                resolve();
              })
              .catch(() => {
                this.gravatar = null;
                resolve();
              });
          }, config.searchInputsTimeout);
        } else {
          resolve();
        }
      });
    },

    rotate(rotationAngle) {
      // Rotates the image
      this.$refs.croppieRef.rotate(rotationAngle);
    },

    getGravatar: function () {
      return "data:image/jpeg;base64," + this.gravatar;
    },

    crop() {
      let options = {
        format: "jpeg",
        size: { width: 128, height: 128 },
      };
      this.cropping = true;

      this.$refs.croppieRef.result(options, (output) => {
        this.croppieVisible = false;
        userManager
          .uploadAvatar(output.replace("data:image/jpeg;base64,", ""))
          .then(() => {
            this.croppedAvatar = output;
            this.cropperDialog = false;
            this.croppieVisible = false;
            this.avatarChanged = false;
            this.gravatar = null;
            this.gravatarMode = 0;
            this.cropping = false;
            this.updateCachedAvatar();
            EventBus.$emit("avatar-changed");
          })
          .catch((err) => {
            console.log(err);
            this.cropping = false;
          });
      });
    },

    closeCrop() {
      this.cropperDialog = false;
      this.croppieVisible = true;
      this.croppedAvatar = null;
      this.avatarLoader = null;
    },

    useGravatar() {
      // Fetch a new gravatar using the email address
      avatars
        .fetchGravatar(this.email)
        .then((result) => {
          this.gravatar = result;
          this.croppedAvatar = null;
          this.avatarChanged = true;
          this.gravatarMode = 1;
        })
        .catch(() => {
          this.gravatar = null;
        });
    },

    updateCachedAvatar() {
      avatars.removeAvatarFromCache(this.$store.state.user.id);
      if (this.gravatarMode == false) {
        let filteredAvatar = this.croppedAvatar.replace(
          "data:image/jpeg;base64,",
          ""
        );
        this.$store.state.user.avatar = filteredAvatar;
        localStorageService.setAvatar(filteredAvatar);
      } else if (this.gravatarMode == true) {
        this.$store.state.user.avatar = this.gravatar;
        localStorageService.setAvatar(this.gravatar);
      }
      this.loadAvatar();
    },
  },

  watch: {
    avatarLoader: {
      handler: function () {
        if (this.avatarLoader) {
          this.saved = false;
          this.cropperDialog = true;
          this.croppieVisible = false;
          this.croppedAvatar = null;
          setTimeout(() => {
            this.croppieVisible = true;
            this.$refs.croppieRef.bind({
              url: this.avatarLoader.imageURL,
            });
          }, 400);
        } else {
          this.croppieVisible = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
.avatar_camera {
  position: absolute;
  top: 45px;
  left: 45px;
}
</style>
