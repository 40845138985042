import { DateTime } from "luxon";
require("luxon-parser");

//import store from "@/store";

Date.isLeapYear = function (year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

Date.getDaysInMonth = function (year, month) {
  return [
    31,
    Date.isLeapYear(year) ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ][month];
};

Date.prototype.isLeapYear = function () {
  return Date.isLeapYear(this.getFullYear());
};

Date.prototype.getDaysInMonth = function () {
  return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
};

Date.prototype.addMonths = function (value) {
  var n = this.getDate();
  this.setDate(1);
  this.setMonth(this.getMonth() + value);
  this.setDate(Math.min(n, this.getDaysInMonth()));
  return this;
};

const DatesHelpers = (function () {
  return {
    dateFromIsoToLocale: function (value, locale) {
      if (!value) return null;
      const d = DateTime.fromISO(value);
      return d.setLocale(locale).toLocaleString(DateTime.DATE_SHORT);
    },

    validateDate: function (date, locale) {
      if (!date) return false;
      const res = DateTime.fromAny(date, { locale: locale });
      return res.invalid;
    },

    dateFromLocaleToIso: function (date, locale) {
      try {
        let parts = date.split("-");
        let year = parts[2];
        let month = parts[0];
        let day = parts[1];
        if (locale.toLowerCase().substr(0, 2) == "it") {
          parts = date.split("/");

          year = parts[2];
          month = parts[1];
          day = parts[0];
        }
        const isoDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
          2,
          "0"
        )}`;
        return isoDate;
      } catch {
        return null;
      }
    },

    mysql2JsDate: function (str) {
      let t = str.split(/[- :]/);
      return new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
    },

    mysql2JsLocal: function (str) {
      let g = this.mysql2JsDate(str);
      return new Date(g.getTime() - g.getTimezoneOffset() * 60000);
    },

    getDayOfWeek: function (d) {
      return d.getDay() == 0 ? 7 : d.getDay();
    },

    formatDate: function (d) {
      return [
        d.getFullYear(),
        (d.getMonth() + 1).toString().padStart(2, "0"),
        d.getDate().toString().padStart(2, "0"),
      ].join("-");
    },

    formatTime: function (d) {
      return [
        d.getHours().toString().padStart(2, "0"),
        d.getMinutes().toString().padStart(2, "0"),
      ].join(":");
    },

    getDateTime: function (strDate, strTime) {
      return new Date(strDate + " " + strTime);
    },
  };
})();

export default DatesHelpers;
