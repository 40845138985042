<template>
  <v-dialog
    style="z-index: 99999"
    :overlay-opacity="overlayOpacity"
    :persistent="persistent"
    :max-width="maxWidth"
    :value="value"
  >
    <ValidationObserver
      :ref="observerProp"
      v-slot="{ invalid, validate, reset, errors }"
    >
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0"
            ><slot
              name="title"
              :validate="validate"
              :reset="reset"
              :invalid="invalid"
              :errors="errors"
            ></slot
          ></span>
        </div>

        <v-card-text class="pa-2 text-center card-border-bottom">
          <slot
            :validate="validate"
            :reset="reset"
            :invalid="invalid"
            :errors="errors"
          ></slot>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <slot
            :validate="validate"
            :reset="reset"
            :invalid="invalid"
            :errors="errors"
            name="footer"
          ></slot>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {};
  },

  watch: {},

  props: {
    value: {
      type: Boolean,
      required: false,
    },

    overlayOpacity: {
      type: String,
      required: false,
      default: "1.0",
    },

    persistent: {
      type: Boolean,
      required: false,
      default: false,
    },

    maxWidth: {
      type: String,
      required: false,
      default: null,
    },

    observerProp: {
      type: String,
      required: false,
      default: "obs",
    },

    parentDisabledObserver: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    getClass() {
      let baseClass = this.$props.secondary
        ? this.$props.disabled
          ? "disabled"
          : "secondary"
        : this.$props.disabled
        ? "disabled"
        : "default";
      return (
        "font-weight-bolder btn-default py-4 px-8 mt-4 bg-gradient-" + baseClass
      );
    },

    getTextColor() {
      return "color : " + this.$props.textColor;
    },
  },

  components: {},

  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
