import Vue from "vue";
import VueI18n from "vue-i18n";

import it from "@/../../shared/app/locales/it.json";
import en from "@/../../shared/app/locales/en.json";

let messages = {
  it: it,
  en: en,
};

Vue.use(VueI18n);

export default new VueI18n({
  locale: "it",
  fallbackLocale: "en",
  messages: messages,
});
