var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"justify-center d-flex mt-n16"},[_c('v-col',{staticClass:"mx-auto",attrs:{"lg":"4","md":"7"}},[_c('v-card',{staticClass:"card-shadow border-radius-xl"},[_c('div',{staticClass:"card-padding text-center"},[_c('v-row',{staticClass:"d-flex"},[_c('v-col',{staticClass:"text-right"},[(_vm.$store.state.identity.logo_file)?_c('v-img',{staticClass:"mx-auto",attrs:{"max-width":"128","max-height":"128","src":'data:image/png;base64,' + _vm.$store.state.identity.logo_file}}):_vm._e()],1)],1)],1),_c('div',{staticClass:"mt-2 position-relative text-center"},[_c('p',{staticClass:"text-sm font-weight-bold mb-2 text-secondary short-text-border text-border d-inline z-index-2 bg-white px-3"},[_vm._v(" "+_vm._s(_vm.$t("login-subtitle"))+" ")])]),_c('v-form',{attrs:{"id":"login-form"},on:{"submit":function($event){$event.preventDefault();return _vm.login(false)}}},[_c('ValidationObserver',{ref:"obs",attrs:{"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('div',{staticClass:"card-padding"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('FormTextInput',{attrs:{"disabled":_vm.logging,"error-messages":errors,"success":valid},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login(false)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('FormTextInput',{attrs:{"disabled":_vm.logging,"outlined":"","error-messages":errors,"success":valid,"autocomplete":"off","append-icon":_vm.password_view ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.password_view ? 'password' : 'text'},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login(false)},"click:append":function () { return (_vm.password_view = !_vm.password_view); }},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"text-center"},[(!_vm.logging)?_c('UniversalButton',{attrs:{"disabled":invalid},on:{"click":function($event){return _vm.login(false)}}},[_vm._v(" "+_vm._s(_vm.$t("login-button"))+" ")]):_vm._e()],1),(_vm.logging)?_c('p',{staticClass:"text-center text-sm text-body mb-0 mt-2"},[_c('v-progress-circular',{staticClass:"mb-2",attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"#3A416F"}})],1):_vm._e(),(_vm.loginError)?_c('div',{staticClass:"mt-2"},[_c('v-alert',{staticClass:"alert alert-danger",attrs:{"close-icon":"fas fa-times font-size-root text-white","type":"error","dense":"","dismissible":""}},[_c('span',{staticClass:"text-uppercase",domProps:{"textContent":_vm._s(_vm.loginError)}})])],1):_vm._e(),(!_vm.logging)?_c('p',{staticClass:"text-center text-sm text-body mt-3 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("login-lost-password"))),_c('br'),_c('router-link',{staticClass:"text-dark text-decoration-none font-weight-bolder",attrs:{"to":"/recovery"}},[_vm._v(" "+_vm._s(_vm.$t("login-lost-password-link"))+" ")])],1):_vm._e()],1)])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }