<template>
  <UniversalDialog
    persistent
    overlay-opacity="0.6"
    max-width="640"
    :value="openDialog"
  >
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->

    <template v-slot:footer>
      <UniversalButton width="120" @click="confirm(true)" :color="okvariant">
        {{ $t("gbl-yes") }}
      </UniversalButton>

      <UniversalButton
        width="120"
        @click="confirm(false)"
        :color="cancelvariant"
        secondary
      >
        {{ $t("gbl-no") }}
      </UniversalButton>
    </template>

    <template v-slot:title>
      <v-row
        ><v-col cols="11">{{ title }}</v-col
        ><v-col cols="1" class="text-right"
          ><v-btn style="margin-right: 0" icon @click="dismiss()"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-col
        ></v-row
      >
    </template>

    <template v-slot:default>
      <span class="body-1">{{ text }}</span>
    </template>
  </UniversalDialog>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      text: "",
      okvariant: "#cb0c9f",
      cancelvariant: "#cb0c9f",
      openDialog: false,
      dialogResolve: null,
      dialogReject: null,
    };
  },

  props: {
    showClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  methods: {
    show(text, title, okvariant = "primary", cancelvariant = "secondary") {
      return new Promise((resolve, reject) => {
        this.value = true;
        this.title = title;
        this.text = text;
        this.okvariant = okvariant;
        this.cancelvariant = cancelvariant;
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;
      });
    },

    confirm(state) {
      this.openDialog = false;
      this.dialogResolve(state);
    },

    dismiss() {
      this.openDialog = false;
      this.dialogReject();
    },

  },
};
</script>
