<template>
  <UniversalDialog
    persistent
    overlay-opacity="0.6"
    max-width="640"
    :value="openDialog"
  >
    <template v-slot:footer>
      <UniversalButton width="120" @click="confirm()" :color="okvariant">
        {{ $t("gbl-ok") }}
      </UniversalButton>

      <UniversalButton
        width="120"
        @click="dismiss()"
        :color="cancelvariant"
        secondary
      >
        {{ $t("msgbox-cancel") }}
      </UniversalButton>
    </template>

    <template v-slot:title>
      <v-row
        ><v-col cols="11">{{ title }}</v-col
        ><v-col cols="1" class="text-right"
          ><v-btn
            style="margin-right: 0"
            v-if="showClose"
            icon
            @click="dismiss()"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-col
        ></v-row
      >
    </template>

    <template v-slot:default>
      <span v-if="text">{{ text }}</span>
      <ValidationObserver disabled v-if="required" ref="obs" v-slot="{}">
        <ValidationProvider rules="required" v-slot="{ errors, valid }">
          <FormTextInput
            v-model="value"
            :label="label"
            :error-messages="errors"
            :success="valid"
            required
          ></FormTextInput>
        </ValidationProvider>
      </ValidationObserver>
      <FormTextInput
        v-if="!required"
        v-model="value"
        class="ml-auto"
        :label="label"
      />
      <FormTextInput
        v-model="color"
        readonly
        hide-details
        class="ma-0 pa-0"
        outlined
        :label="color_title"
      >
        <template v-slot:append>
          <v-menu
            v-model="menu"
            top
            nudge-bottom="105"
            nudge-left="16"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on }">
              <div :style="swatchStyle" v-on="on" />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker v-model="color" flat />
              </v-card-text>
            </v-card>
          </v-menu>
        </template>
      </FormTextInput>
    </template>
  </UniversalDialog>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      label: "",
      value: "",
      text: null,
      initial_value: "",
      string_state: true,
      context: null,
      color_title: null,
      color: null,
      required: true,
      mustChange: false,
      okvariant: "primary",
      cancelvariant: "secondary",
      openDialog: false,
      dialogResolve: null,
      dialogReject: null,
      colorPickerColor: "#ffffff",
      menu: false,
    };
  },

  props: {
    showClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    swatchStyle() {
      const { color, menu } = this;
      return {
        backgroundColor: color,
        cursor: "pointer",
        height: "25px",
        width: "25px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
  },
  methods: {
    show(
      title,
      label,
      text,
      initial_value,
      color_title,
      initial_color,
      context = null,
      required = true,
      mustChange = true,
      okvariant = "primary",
      cancelvariant = "secondary"
    ) {
      return new Promise((resolve, reject) => {
        this.value = initial_value;
        this.title = title;
        this.label = label;
        this.text = text;
        this.initial_value = initial_value;
        this.color_title = color_title;
        this.color = initial_color;
        this.okvariant = okvariant;
        this.cancelvariant = cancelvariant;
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;
        this.required = required;
        this.context = context;
        this.mustChange = mustChange;
      });
    },

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      if (!this.required || this.value.length > 0 || this.color.length > 0) {
        if (
          this.value != this.initial_value ||
          this.color != this.initial_color ||
          !this.mustChange
        ) {
          this.openDialog = false;
          this.dialogResolve({
            context: this.context,
            value: { text: this.value, color: this.color },
          });
        }
      }
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    },
  },
};
</script>
